import React from "react";
import UserManagement from "./user/UserManagement";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { Gamepad, People, TextSnippet } from "@mui/icons-material";
import ButtonGroupManagement from "./buttonGroups/ButtonGroupManagement";

interface AdminDialogContentProps {
  navIndex: number;
  setNavIndex: (i: number) => void;
}

const AdminDialogContent = ({
  navIndex,
  setNavIndex,
}: AdminDialogContentProps) => {
  return (
    <Box>
      <Box sx={{ pb: 7 }}>
        {navIndex === 0 && <ButtonGroupManagement />}
        {navIndex === 1 && <UserManagement />}
        {navIndex === 2 && (
          <Typography align="center" mt={5} color="grey">
            Not Implemented
          </Typography>
        )}
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={navIndex}
          onChange={(event, newValue) => {
            setNavIndex(newValue);
          }}
        >
          <BottomNavigationAction label="Button Groups" icon={<Gamepad />} />
          <BottomNavigationAction label="Users" icon={<People />} />
          <BottomNavigationAction label="Event Log" icon={<TextSnippet />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default AdminDialogContent;
