import React from "react";
import { useButtonGroupStore } from "./buttonGroupStore";
import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import EditButtonGroupDialog from "./editButtonGroup/EditButtonGroupDialog";
import { AdminButtonGroup } from "./AdminButtonGroup";

const ButtonGroupManagement = () => {
  const groups = useButtonGroupStore((state) => state.groups);
  const fetchGroups = useButtonGroupStore((state) => state.fetchGroups);

  const [editGroup, setEditGroup] = React.useState<
    AdminButtonGroup | undefined
  >(undefined);

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const listItems = groups.map((group) => (
    <React.Fragment key={group.id}>
      <ListItemButton onClick={() => setEditGroup(group)}>
        <ListItemText
          primary={group.name}
          secondary={group.buttons.map((button) => button.name).join(", ")}
        />
        <ChevronRight color="action" />
      </ListItemButton>
      <Divider />
    </React.Fragment>
  ));

  return (
    <React.Fragment>
      <EditButtonGroupDialog
        editGroup={editGroup}
        handleClose={() => setEditGroup(undefined)}
      />
      <List>{listItems}</List>
    </React.Fragment>
  );
};

export default ButtonGroupManagement;
