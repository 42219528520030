import React from "react";
import "./App.css";
import Layout from "./components/layout/Layout";
import { createTheme, ThemeProvider } from "@mui/material";
import LoginUI from "./components/login/LoginUI";
import { Route, Routes, useNavigate } from "react-router-dom";
import { RequireAuth, useAuth } from "./auth/AuthProvider";
import { SnackbarProvider } from "notistack";
import axios from "axios";
import MainUI from "./components/main/MainUI";

const theme = createTheme({
  palette: {
    primary: {
      main: "#285979",
    },
    secondary: {
      main: "#0097a7",
    },
  },
});

function App() {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    auth.tryAuth(
      () => {
        navigate("/", { replace: true });
      },
      () => {}
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<LoginUI />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <MainUI />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
