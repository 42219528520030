import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { AdminButtonGroup } from "./AdminButtonGroup";

interface ButtonGroupState {
  groupsLoading: boolean;
  groups: AdminButtonGroup[];
  fetchGroups: () => void;
  createGroup: (name: string) => void;
  deleteGroup: (id: number, onSuccess: VoidFunction) => void;
}

export const useButtonGroupStore = create<ButtonGroupState>()(
  devtools((set, get) => ({
    groupsLoading: false,
    groups: [],
    fetchGroups: async () => {
      set({ groupsLoading: true });
      axios
        .get<AdminButtonGroup[]>("/buttons/admin/list", {
          withCredentials: true,
        })
        .then(({ data }) => set({ groups: data }))
        .catch((e) => console.error(e))
        .finally(() => set({ groupsLoading: false }));
    },
    createGroup: async (name: string) => {
      axios
        .post<AdminButtonGroup>(
          "/buttons/admin/group",
          { name },
          { withCredentials: true }
        )
        .then(({ data }) => {
          get().fetchGroups();
        });
    },
    deleteGroup: async (id: number, onSuccess) => {
      axios
        .delete<number>("/buttons/admin/group/" + id, { withCredentials: true })
        .then(({ data }) => {
          onSuccess();
          get().fetchGroups();
        });
    },
  }))
);
