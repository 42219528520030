import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline } from "@mui/material";
import axios from "axios";
import { AuthProvider } from "./auth/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const API_HOST_URL =
  process.env.NODE_ENV === "production"
    ? // @ts-ignore
      window.env.API_HOST_URL
    : "http://localhost:8000/api";

axios.defaults.baseURL = API_HOST_URL;

export const authAxios = axios.create({ baseURL: API_HOST_URL });

// interceptor only added for non auth requests
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      await authAxios.get("/auth/refresh", { withCredentials: true });

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CssBaseline enableColorScheme />
    <AuthProvider>
      <BrowserRouter>
        <GoogleReCaptchaProvider reCaptchaKey="6LfbI0gjAAAAABb0K1zrmR8Dlhs5mrlBp41hL1v6">
          <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
