import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../auth/AuthProvider";
import { AdminUser } from "./AdminUser";
import { useUserAdminStore } from "./userAdminStore";

interface ModifyUserDialogProps {
  user: AdminUser | null;
  closeDialog: () => void;
}

const ModifyUserDialog = ({ user, closeDialog }: ModifyUserDialogProps) => {
  const auth = useAuth();

  const updateUser = useUserAdminStore((state) => state.updateUser);

  const [name, setName] = React.useState<string>("");
  const [surname, setSurname] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [passwordValid, setPasswordValid] = React.useState<boolean>(true);
  const [password, setPassword] = React.useState<string>("");

  const [admin, setAdmin] = React.useState<boolean>(false);
  const [enabled, setEnabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (user) {
      setName(user.name);
      setSurname(user.surname);
      setEmail(user.email);

      setPassword("");

      setAdmin(user.admin);
      setEnabled(user.enabled);
    }
  }, [user]);

  const handleClose = () => {
    setPasswordValid(true);
    closeDialog();
  };

  const handleSave = () => {
    if (user) {
      updateUser({
        id: user.id,
        name,
        surname,
        password: password !== "" ? password : undefined,
        admin,
        enabled,
      });
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={user !== null} onClose={handleClose}>
        <DialogTitle>Modify User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Surname"
            type="text"
            fullWidth
            variant="standard"
            value={surname}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSurname(event.target.value);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Email Address"
            disabled
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            size="small"
            label="Password (Set To Change)"
            type="text"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordValid(event.target.value.length >= 10);
              setPassword(event.target.value);
            }}
            error={!passwordValid}
            helperText="Must be at least 10 characters long"
          />
          <FormGroup sx={{ mt: 1, mb: 1 }}>
            <FormControlLabel
              disabled={auth.user?.id === user?.id}
              control={
                <Switch checked={admin} onClick={() => setAdmin(!admin)} />
              }
              label="Administrator"
            />
            <FormControlLabel
              disabled={auth.user?.id === user?.id}
              control={
                <Switch
                  checked={enabled}
                  onClick={() => setEnabled(!enabled)}
                />
              }
              label="User Enabled"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!passwordValid}
            onClick={handleSave}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ModifyUserDialog;
