import React from "react";
import { useButtonGroupStore } from "../buttonGroups/buttonGroupStore";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
} from "@mui/material";
import { Gamepad } from "@mui/icons-material";
import { AdminUser } from "./AdminUser";
import axios from "axios";
import { Button } from "../../../model/Button";

interface ModifyButtonAccessDialogContentProps {
  user: AdminUser;
}

const ModifyButtonAccessDialogContent = ({
  user,
}: ModifyButtonAccessDialogContentProps) => {
  const groups = useButtonGroupStore((state) => state.groups);
  const fetchGroups = useButtonGroupStore((state) => state.fetchGroups);

  const [switchState, setSwitchState] = React.useState<Map<number, boolean>>(
    new Map()
  );
  const [buttonAssignments, setButtonAssignments] = React.useState<number[]>(
    []
  );

  React.useEffect(() => {
    fetchGroups();
    fetchButtonAssignments(user.id);
  }, []);

  React.useEffect(() => {
    let states = new Map(switchState);
    if (groups.length > 0) {
      const buttons: Button[] = groups.flatMap((group) => group.buttons);
      buttons.forEach((button) => {
        states.set(
          button.id,
          !!buttonAssignments.find((ba) => button.id === ba)
        );
      });
    }
    setSwitchState(states);
  }, [groups, buttonAssignments]);

  const fetchButtonAssignments = (userId: number) => {
    axios
      .get<number[]>("/user/admin/assign/" + userId, { withCredentials: true })
      .then(({ data }) => setButtonAssignments(data))
      .catch(() => {
        console.error("error loading user button assignments");
      });
  };

  const isChecked = (buttonId: number): boolean => {
    return !!switchState.get(buttonId);
  };

  const setState = (buttonId: number, state: boolean) => {
    let newState = new Map(switchState);
    newState.set(buttonId, state);
    setSwitchState(newState);
  };

  const handleChange = (buttonId: number) => {
    const currentState = !!switchState.get(buttonId);
    setState(buttonId, !currentState);
    axios
      .put(
        "/user/admin/assign",
        { userId: user.id, buttonId, granted: !currentState },
        { withCredentials: true }
      )
      .then(() => {})
      .catch(() => {
        setState(buttonId, currentState);
      });
  };

  const items = (): any => {
    return groups.map((group) => {
      return (
        <React.Fragment key={group.id}>
          <ListSubheader>{group.name}</ListSubheader>
          {group.buttons.map((button) => (
            <ListItem key={button.id}>
              <ListItemIcon>
                <Gamepad />
              </ListItemIcon>
              <ListItemText>{button.name}</ListItemText>
              <Switch
                edge="end"
                checked={isChecked(button.id)}
                onChange={() => handleChange(button.id)}
              />
            </ListItem>
          ))}
        </React.Fragment>
      );
    });
  };

  return <List>{items()}</List>;
};

export default ModifyButtonAccessDialogContent;
