import React from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Settings, Logout, Key } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import { useAdminStore } from "../admin/adminStore";
import AdminDialog from "../admin/AdminDialog";
import { useSnackbar } from "notistack";
import ChangePasswordDialog from "../common/ChangePasswordDialog";

const Layout = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const setAdminDialogOpen = useAdminStore((state) => state.setDialogOpen);

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    React.useState<boolean>(false);

  const handleLogout = () => {
    auth.logout(
      () => {
        enqueueSnackbar("Logout successful", { variant: "success" });
        navigate("/");
      },
      () => {
        enqueueSnackbar("Error while logging you out", { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    );
  };

  const handleOpenAdminDialog = () => {
    setAdminDialogOpen(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AdminDialog />
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        closeDialog={() => setChangePasswordDialogOpen(false)}
      />
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            AST Buttons
          </Typography>
          {auth.user && (
            <IconButton
              size="large"
              aria-label="change password"
              aria-controls="menu-appbar"
              color="inherit"
              onClick={() => setChangePasswordDialogOpen(true)}
            >
              <Key />
            </IconButton>
          )}
          {auth.user && auth.user.admin && (
            <IconButton
              size="large"
              aria-label="admin menu"
              aria-controls="menu-appbar"
              color="inherit"
              onClick={handleOpenAdminDialog}
            >
              <Settings />
            </IconButton>
          )}
          {auth.user && (
            <IconButton
              size="large"
              aria-label="user logout"
              aria-controls="menu-appbar"
              color="inherit"
              onClick={handleLogout}
            >
              <Logout />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Outlet />
    </Box>
  );
};

export default Layout;
