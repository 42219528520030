import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowBack, Delete } from "@mui/icons-material";
import { AdminButtonGroup } from "../AdminButtonGroup";
import DeleteButtonGroupDialog from "./DeleteButtonGroupDialog";
import EditButtonGroupDialogContent from "./EditButtonGroupDialogContent";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface EditButtonGroupDialogProps {
  editGroup: AdminButtonGroup | undefined;
  handleClose: () => void;
}

const EditButtonGroupDialog = ({
  editGroup,
  handleClose,
}: EditButtonGroupDialogProps) => {
  const [groupToDelete, setGroupToDelete] = React.useState<
    AdminButtonGroup | undefined
  >(undefined);

  return (
    <React.Fragment>
      <DeleteButtonGroupDialog
        group={groupToDelete}
        handleClose={() => setGroupToDelete(undefined)}
        handleSuccess={() => handleClose()}
      />
      <Dialog
        fullScreen
        open={editGroup !== undefined}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBack />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {editGroup?.name}
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => setGroupToDelete(editGroup)}
              aria-label="close"
            >
              <Delete />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <EditButtonGroupDialogContent groupId={editGroup?.id} />
      </Dialog>
    </React.Fragment>
  );
};

export default EditButtonGroupDialog;
