import React from "react";
import { Typography } from "@mui/material";

const RecaptchaBranding = () => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 0, mb: 4 }}
    >
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </Typography>
  );
};

export default RecaptchaBranding;
