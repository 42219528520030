import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAdminStore } from "./adminStore";
import { Add, Close } from "@mui/icons-material";
import AdminDialogContent from "./AdminDialogContent";
import CreateButtonGroupDialog from "./buttonGroups/CreateButtonGroupDialog";
import CreateUserDialog from "./user/CreateUserDialog";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ActionData {
  icon?: React.ReactNode;
}

const ACTIONS: ActionData[] = [
  {
    icon: <Add />,
  },
  {
    icon: <Add />,
  },
  {},
];

const AdminDialog = () => {
  const [navIndex, setNavIndex] = React.useState(0);

  const [createButtonGroupDialogOpen, setCreateButtonGroupDialogOpen] =
    React.useState<boolean>(false);
  const [createUserDialogOpen, setCreateUserDialogOpen] =
    React.useState<boolean>(false);

  const open = useAdminStore((state) => state.dialogOpen);
  const setAdminDialogOpen = useAdminStore((state) => state.setDialogOpen);

  const handleClose = () => {
    setAdminDialogOpen(false);
    setNavIndex(0);
  };

  const handleActionClick = (i: number) => {
    if (i === 0) {
      setCreateButtonGroupDialogOpen(true);
    }
    if (i === 1) {
      setCreateUserDialogOpen(true);
    }
  };

  return (
    <React.Fragment>
      <CreateButtonGroupDialog
        open={createButtonGroupDialogOpen}
        handleClose={() => setCreateButtonGroupDialogOpen(false)}
      />
      <CreateUserDialog
        open={createUserDialogOpen}
        closeDialog={() => setCreateUserDialogOpen(false)}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Administration
            </Typography>
            {ACTIONS.map(
              (action, index) =>
                action &&
                action.icon &&
                index === navIndex && (
                  <IconButton
                    key={index}
                    color="inherit"
                    onClick={() => handleActionClick(index)}
                  >
                    {action.icon}
                  </IconButton>
                )
            )}
          </Toolbar>
        </AppBar>
        <Toolbar />
        <AdminDialogContent navIndex={navIndex} setNavIndex={setNavIndex} />
      </Dialog>
    </React.Fragment>
  );
};

export default AdminDialog;
