import React from "react";
import { Group } from "../../model/Group";
import Grid2 from "@mui/material/Unstable_Grid2";
import TriggerButton from "./TriggerButton/TriggerButton";
import { Box } from "@mui/material";

interface GroupDialogContentProps {
  group?: Group;
}

const GroupDialogContent = ({ group }: GroupDialogContentProps) => {
  return (
    <Box sx={{ m: 2 }}>
      <Grid2 container spacing={2}>
        {group?.buttons.map((button) => (
          <Grid2 xs={6} md={3} key={button.id}>
            <TriggerButton button={button} />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default GroupDialogContent;
