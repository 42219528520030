import React from "react";
import { Group } from "../../model/Group";
import axios from "axios";
import { Box, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupDialog from "./GroupDialog";

const MainUI = () => {
  const [groups, setGroups] = React.useState<Group[]>([]);

  const [openGroup, setOpenGroup] = React.useState<Group | undefined>(
    undefined
  );

  React.useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    axios
      .get<Group[]>("/buttons/list", { withCredentials: true })
      .then(({ data }) => {
        setGroups(data);
      })
      .catch((error) => {
        console.error("couldnt load buttons", error);
      });
  };

  return (
    <React.Fragment>
      <GroupDialog
        group={openGroup}
        handleClose={() => setOpenGroup(undefined)}
      />
      <Box sx={{ m: 2 }}>
        <Grid2 container spacing={2}>
          {groups.map((group) => (
            <Grid2 xs={6} md={3} key={group.id}>
              <Button
                variant="contained"
                sx={{ height: 100 }}
                onClick={() => setOpenGroup(group)}
                fullWidth
                endIcon={<WorkspacesIcon />}
              >
                {group.name}
              </Button>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </React.Fragment>
  );
};

export default MainUI;
