import React from "react";
import { Button as ButtonModel } from "../../../model/Button";
import { Box, Button, CircularProgress } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { TriggerState } from "./TriggerState";
import axios from "axios";

interface ProgressButtonProps {
  button: ButtonModel;
}

const TriggerButton = ({ button }: ProgressButtonProps) => {
  const [triggerState, setTriggerState] = React.useState<TriggerState>(
    TriggerState.IDLE
  );
  const returnToIdleTimer = React.useRef<number>();

  React.useEffect(() => {
    return () => {
      clearTimeout(returnToIdleTimer.current);
    };
  }, []);

  const buttonSx = {
    ...(triggerState === TriggerState.SUCCESS && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
    ...(triggerState === TriggerState.FAILURE && {
      bgcolor: red[500],
      "&:hover": {
        bgcolor: red[700],
      },
    }),
  };

  const handleButtonClick = () => {
    if (triggerState !== TriggerState.LOADING) {
      setTriggerState(TriggerState.LOADING);
      clearTimeout(returnToIdleTimer.current);
      axios
        .get("/buttons/trigger/" + button.id, { withCredentials: true })
        .then(() => {
          setTriggerState(TriggerState.SUCCESS);
        })
        .catch((e) => {
          console.error("button trigger failed", e);
          setTriggerState(TriggerState.FAILURE);
        })
        .finally(() => {
          returnToIdleTimer.current = window.setTimeout(() => {
            setTriggerState(TriggerState.IDLE);
          }, 3000);
        });
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        variant="contained"
        sx={{ ...buttonSx, height: 100 }}
        disabled={triggerState === TriggerState.LOADING}
        onClick={handleButtonClick}
        fullWidth
      >
        {button.name}
      </Button>
      {triggerState === TriggerState.LOADING && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};

export default TriggerButton;
