import React from "react";
import { restAuthProvider } from "./auth";
import { Navigate, useLocation } from "react-router-dom";

interface User {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone?: string;
  created: string;
  admin: boolean;
  enabled: boolean;
}

interface AuthContextType {
  user: User | null;
  tryAuth: (onSuccess: () => void, onFailure: () => void) => void;
  login: (
    email: string,
    password: string,
    gRecaptchaResponse: string,
    onSuccess: () => void,
    onFailure: () => void
  ) => void;
  logout: (onSuccess: () => void, onFailure: () => void) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  let [user, setUser] = React.useState<User | null>(null);

  let tryAuth = (onSuccess: () => void, onFailure: () => void) => {
    return restAuthProvider.tryAuth((user) => {
      setUser(user);
      onSuccess();
    }, onFailure);
  };

  let login = (
    email: string,
    password: string,
    gRecaptchaResponse: string,
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    return restAuthProvider.login(
      { email, password, gRecaptchaResponse },
      (user) => {
        setUser(user);
        onSuccess();
      },
      onFailure
    );
  };

  let logout = (onSuccess: () => void, onFailure: () => void) => {
    return restAuthProvider.logout(() => {
      setUser(null);
      onSuccess();
    }, onFailure);
  };

  let value = { user, tryAuth, login, logout };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  return React.useContext(AuthContext);
};

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = (props) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return props.children;
};

export { AuthProvider, useAuth, RequireAuth };
export type { User };
