import create from "zustand";
import { devtools } from "zustand/middleware";
import { AdminUser } from "./AdminUser";
import axios from "axios";
import { UpdateUser } from "./UpdateUser";
import { User } from "../../../auth/AuthProvider";
import { CreateUser } from "./CreateUser";

interface UserAdminState {
  usersLoading: boolean;
  users: AdminUser[];
  fetchUsers: () => void;
  updateUser: (user: UpdateUser) => void;
  createUser: (user: CreateUser) => void;
}

export const useUserAdminStore = create<UserAdminState>()(
  devtools((set, get) => ({
    usersLoading: false,
    users: [],
    fetchUsers: async () => {
      set({ usersLoading: true });
      axios
        .get<AdminUser[]>("/user/admin/list", { withCredentials: true })
        .then(({ data }) => set({ users: data }))
        .catch((e) => console.error(e))
        .finally(() => set({ usersLoading: false }));
    },
    updateUser: async (user) => {
      axios
        .patch<number>("/user/admin/update", user, { withCredentials: true })
        .then((data) => get().fetchUsers());
    },
    createUser: async (user) => {
      axios
        .post<User>("/user/admin/register", user, { withCredentials: true })
        .then((data) => get().fetchUsers());
    },
  }))
);
