import React from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import Copyright from "./Copyright";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import { useSnackbar } from "notistack";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import RecaptchaBranding from "./RecaptchaBranding";

const LoginUI = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [recaptchaToken, setRecaptchaToken] = React.useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("preLogin");
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const from = location.state?.from?.pathname || "/";

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email") as string;
    const password = data.get("password") as string;

    setLoading(true);
    auth.login(
      email,
      password,
      recaptchaToken!,
      () => {
        enqueueSnackbar("Login successful", { variant: "success" });
        navigate(from, { replace: true });
        setLoading(false);
      },
      () => {
        enqueueSnackbar("Login has failed", { variant: "error" });
        setLoading(false);
      }
    );

    setRecaptchaToken(undefined);
    handleReCaptchaVerify();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox value="remember" color="primary" />}*/}
          {/*  label="Remember me"*/}
          {/*  disabled*/}
          {/*/>*/}
          <Box sx={{ position: "relative", mt: 3, mb: 2 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!recaptchaToken || loading}
            >
              Sign In
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <RecaptchaBranding />
      <Copyright />
    </Container>
  );
};

export default LoginUI;
