import React from "react";
import {
  Divider,
  Fab,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import { AdminButtonGroup } from "../AdminButtonGroup";
import axios from "axios";
import { Button } from "../../../../model/Button";
import AddEditButtonDialog from "./AddEditButtonDialog";
import { useButtonGroupStore } from "../buttonGroupStore";

interface EditButtonGroupDialogContentProps {
  groupId: number | undefined;
}

const EditButtonGroupDialogContent = ({
  groupId,
}: EditButtonGroupDialogContentProps) => {
  const theme = useTheme();

  const fetchGroups = useButtonGroupStore((state) => state.fetchGroups);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const [group, setGroup] = React.useState<AdminButtonGroup | undefined>(
    undefined
  );

  const [createEditButton, setCreateEditButton] = React.useState<
    Button | undefined | null
  >(null);

  const openEditButtonDialog = (button: Button) => {
    setCreateEditButton(button);
  };

  const openCreateButtonDialog = () => {
    setCreateEditButton(undefined);
  };

  React.useEffect(() => {
    if (groupId) {
      fetchLocalGroup(groupId);
    }
  }, [groupId]);

  const fetchLocalGroup = (groupId: number) => {
    axios
      .get<AdminButtonGroup>("/buttons/admin/" + groupId, {
        withCredentials: true,
      })
      .then(({ data }) => {
        setGroup(data);
      });
  };

  const buttonItems = group?.buttons.map((button) => (
    <React.Fragment key={button.id}>
      <ListItemButton
        key={button.id}
        onClick={() => openEditButtonDialog(button)}
      >
        <ListItemText
          primary={button.name}
          secondary={button.topic + " <-- " + button.payload}
        />
        <IconButton aria-label="edit">
          <Edit />
        </IconButton>
      </ListItemButton>
      <Divider />
    </React.Fragment>
  ));

  return (
    <React.Fragment>
      <AddEditButtonDialog
        button={createEditButton}
        groupId={groupId!}
        handleClose={() => setCreateEditButton(null)}
        handleRefreshedClose={() => {
          setCreateEditButton(null);
          fetchLocalGroup(groupId!);
          fetchGroups();
        }}
      />
      <Zoom
        in
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exit}ms`,
        }}
        unmountOnExit
      >
        <Fab
          onClick={openCreateButtonDialog}
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
          }}
          aria-label=""
          color="primary"
        >
          <Add />
        </Fab>
      </Zoom>
      {buttonItems !== undefined && buttonItems.length === 0 && (
        <Typography align="center" mt={5} color="grey">
          There are no buttons in this group
        </Typography>
      )}
      <List>{buttonItems}</List>
    </React.Fragment>
  );
};

export default EditButtonGroupDialogContent;
