import React from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import GroupDialogContent from "./GroupDialogContent";
import { Group } from "../../model/Group";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface GroupDialogProps {
  group: Group | undefined;
  handleClose: () => void;
}

const GroupDialog = ({ group, handleClose }: GroupDialogProps) => {
  return (
    <Dialog
      fullScreen
      open={group !== undefined}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar position="fixed">
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <ArrowBack />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {group?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <GroupDialogContent group={group} />
    </Dialog>
  );
};

export default GroupDialog;
