import axios from "axios";
import { User } from "./AuthProvider";
import { authAxios } from "../index";

interface Auth {
  isAuthenticated: boolean;
  tryAuth: (onSuccess: (user: User) => void, onFailure: () => void) => void;
  login: (
    data: { email: string; password: string; gRecaptchaResponse: string },
    onSuccess: (user: User) => void,
    onFailure: () => void
  ) => void;
  logout: (onSuccess: () => void, onFailure: () => void) => void;
}

const restAuthProvider: Auth = {
  isAuthenticated: false,
  tryAuth: (onSuccess, onFailure) => {
    authAxios
      // axios
      .get<User>("/user/details", { withCredentials: true })
      .then(({ data: user }) => {
        restAuthProvider.isAuthenticated = true;
        onSuccess(user);
      })
      .catch(() => {
        authAxios
          // axios
          .get("/auth/refresh", { withCredentials: true })
          .then(() => {
            axios
              .get<User>("/user/details", { withCredentials: true })
              .then(({ data: user }) => {
                restAuthProvider.isAuthenticated = true;
                onSuccess(user);
              })
              .catch(() => {
                onFailure();
              });
          })
          .catch(() => {
            onFailure();
          });
      });
  },
  login: (data, onSuccess, onFailure) => {
    authAxios
      // axios
      .post<User>("/auth/login", data, { withCredentials: true })
      .then(({ data }) => {
        restAuthProvider.isAuthenticated = true;
        onSuccess(data);
      })
      .catch(({ response }) => {
        console.error(response);
        onFailure();
      });
  },
  logout: (onSuccess, onFailure) => {
    authAxios
      // axios
      .get("/auth/logout", { withCredentials: true })
      .then(() => {
        restAuthProvider.isAuthenticated = false;
        onSuccess();
      })
      .catch(({ response }) => {
        console.error(response);
        onFailure();
      });
  },
};

export { restAuthProvider };
