import create from "zustand";
import { devtools } from "zustand/middleware";

interface AdminState {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

export const useAdminStore = create<AdminState>()(
  devtools((set) => ({
    dialogOpen: false,
    setDialogOpen: (open: boolean) => set(() => ({ dialogOpen: open })),
  }))
);
