import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useUserAdminStore } from "./userAdminStore";

interface CreateUserDialogProps {
  open: boolean;
  closeDialog: () => void;
}

const CreateUserDialog = ({ open, closeDialog }: CreateUserDialogProps) => {
  const createUser = useUserAdminStore((state) => state.createUser);

  const [name, setName] = React.useState<string>("");
  const [surname, setSurname] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);

  const [admin, setAdmin] = React.useState<boolean>(false);

  const handleClose = () => {
    setName("");
    setSurname("");
    setEmail("");
    setPassword("");
    setPasswordValid(false);

    setAdmin(false);

    closeDialog();
  };

  const handleSave = () => {
    createUser({
      name,
      surname,
      email,
      password,
      admin,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Name"
          type="text"
          fullWidth
          required
          variant="standard"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Surname"
          type="text"
          fullWidth
          required
          variant="standard"
          value={surname}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSurname(event.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Email Address"
          type="email"
          fullWidth
          required
          variant="standard"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Password"
          type="text"
          fullWidth
          required
          variant="standard"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPasswordValid(event.target.value.length >= 10);
            setPassword(event.target.value);
          }}
          error={!passwordValid}
          helperText="Must be at least 10 characters long"
        />
        <FormGroup sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch checked={admin} onClick={() => setAdmin(!admin)} />
            }
            label="Administrator"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!(name && surname && email && password && passwordValid)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserDialog;
