import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useButtonGroupStore } from "./buttonGroupStore";

interface CreateButtonGroupDialogProps {
  open: boolean;
  handleClose: () => void;
}

const CreateButtonGroupDialog = ({
  open,
  handleClose,
}: CreateButtonGroupDialogProps) => {
  const createGroup = useButtonGroupStore((state) => state.createGroup);

  const [name, setName] = React.useState<string>("");

  const handleCreate = () => {
    createGroup(name);
    setName("");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Button Group</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To create a new button group, please enter the group name below.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Group Name"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!name} onClick={handleCreate} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateButtonGroupDialog;
