import React from "react";
import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  DeveloperMode,
  Edit,
  Person,
  PowerSettingsNew,
} from "@mui/icons-material";
import { useUserAdminStore } from "./userAdminStore";
import ModifyUserDialog from "./ModifyUserDialog";
import { AdminUser } from "./AdminUser";
import ModifyButtonAccessDialog from "./ModifyButtonAccessDialog";

const UserManagement = () => {
  const users = useUserAdminStore((state) => state.users);
  const fetchUsers = useUserAdminStore((state) => state.fetchUsers);

  const [userToModify, setUserToModify] = React.useState<AdminUser | null>(
    null
  );

  const [modifyAccessUser, setModifyAccessUser] =
    React.useState<AdminUser | null>(null);

  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const listItems = users.map((user) => (
    <React.Fragment key={user.id}>
      <ListItem
        key={user.id}
        secondaryAction={
          <React.Fragment>
            <IconButton
              aria-label="edit"
              onClick={() => setModifyAccessUser(user)}
            >
              <DeveloperMode />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => setUserToModify(user)}>
              <Edit />
            </IconButton>
          </React.Fragment>
        }
      >
        <ListItemAvatar>
          <Avatar>{user.enabled ? <Person /> : <PowerSettingsNew />}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              style={{ textDecoration: user.enabled ? "none" : "line-through" }}
            >
              {user.name + " " + user.surname}
            </Typography>
          }
          secondary={
            user.enabled && (
              <React.Fragment>
                {user.admin && (
                  <Chip
                    sx={{ mr: 0.5, mt: 0.5 }}
                    label="Admin"
                    size="small"
                    color="info"
                  />
                )}
                {/*{user.groupsAccess.map(group => <Chip sx={{mr: 0.5, mt: 0.5}} label={group.name} size="small" />)}*/}
              </React.Fragment>
            )
          }
        />
      </ListItem>
      <Divider />
    </React.Fragment>
  ));

  return (
    <React.Fragment>
      <ModifyUserDialog
        user={userToModify}
        closeDialog={() => setUserToModify(null)}
      />
      <ModifyButtonAccessDialog
        user={modifyAccessUser}
        handleClose={() => setModifyAccessUser(null)}
      />
      <List>{listItems}</List>
    </React.Fragment>
  );
};

export default UserManagement;
