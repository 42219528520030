import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";

interface ChangePasswordDialogProps {
  open: boolean;
  closeDialog: () => void;
}

const ChangePasswordDialog = ({
  open,
  closeDialog,
}: ChangePasswordDialogProps) => {
  const [oldPw, setOldPw] = React.useState<string>("");
  const [oldPwValid, setOldPwValid] = React.useState<boolean>(false);
  const [newPw, setNewPw] = React.useState<string>("");
  const [newPwValid, setNewPwValid] = React.useState<boolean>(false);
  const [newPw2, setNewPw2] = React.useState<string>("");
  const [newPw2Valid, setNewPw2Valid] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    setLoading(true);

    axios
      .put(
        "/user/password",
        {
          oldPassword: oldPw,
          newPassword: newPw,
          newPassword2: newPw2,
        },
        { withCredentials: true }
      )
      .then(() => {
        enqueueSnackbar("Password Changed Successfully", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Password Change Has Failed!", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleClose = () => {
    setOldPw("");
    setOldPwValid(false);

    setNewPw("");
    setNewPwValid(false);

    setNewPw2("");
    setNewPw2Valid(false);

    closeDialog();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          All passwords must be at least 10 characters long.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Old Password"
          type="password"
          fullWidth
          required
          variant="standard"
          value={oldPw}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setOldPwValid(event.target.value.length >= 10);
            setOldPw(event.target.value);
          }}
          error={!oldPwValid}
        />
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="New Password"
          type="password"
          fullWidth
          required
          variant="standard"
          value={newPw}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewPwValid(event.target.value.length >= 10);
            setNewPw(event.target.value);
          }}
          error={!newPwValid}
        />
        <TextField
          autoFocus
          margin="dense"
          size="small"
          label="Confirm New Password"
          type="password"
          fullWidth
          required
          variant="standard"
          value={newPw2}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewPw2Valid(event.target.value.length >= 10);
            setNewPw2(event.target.value);
          }}
          error={!newPw2Valid}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Box sx={{ position: "relative" }}>
          <Button
            onClick={handleSave}
            variant="contained"
            disabled={!(oldPwValid && newPwValid && newPw2Valid) || loading}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
