import React from "react";
import { Button as ButtonObj } from "../../../../model/Button";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";
import { UpdateButton } from "./UpdateButton";
import { AddButton } from "./AddButton";

interface AddEditButtonDialogProps {
  button: ButtonObj | undefined | null;
  groupId: number;
  handleClose: VoidFunction;
  handleRefreshedClose: VoidFunction;
}

const AddEditButtonDialog = ({
  button,
  groupId,
  handleClose,
  handleRefreshedClose,
}: AddEditButtonDialogProps) => {
  const [name, setName] = React.useState<string>("");
  const [topic, setTopic] = React.useState<string>("");
  const [payload, setPayload] = React.useState<string>("");

  const handleSave = () => {
    if (button === undefined) {
      createButton({
        groupId,
        name,
        topic,
        payload,
      });
    } else {
      updateButton({
        id: button!.id,
        name,
        topic,
        payload,
      });
    }
  };

  const deleteButton = (buttonId: number) => {
    axios
      .delete("/buttons/admin/button/" + buttonId, { withCredentials: true })
      .then(() => {
        handleRefreshedClose();
      });
  };

  const updateButton = (button: UpdateButton) => {
    axios
      .patch("/buttons/admin/button", button, { withCredentials: true })
      .then(() => {
        handleRefreshedClose();
      });
  };

  const createButton = (button: AddButton) => {
    axios
      .post("/buttons/admin/button", button, { withCredentials: true })
      .then(() => {
        handleRefreshedClose();
      });
  };

  React.useEffect(() => {
    if (button) {
      setName(button.name);
      setTopic(button.topic);
      setPayload(button.payload);
    } else {
      setName("");
      setTopic("");
      setPayload("");
    }
  }, [button]);

  return (
    <Dialog open={button !== null} onClose={handleClose}>
      <DialogTitle>
        {button !== undefined ? "Edit Button" : "Create Button"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="topic"
          label="Topic"
          type="text"
          fullWidth
          variant="standard"
          value={topic}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTopic(event.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="payload"
          label="Payload"
          type="text"
          fullWidth
          variant="standard"
          value={payload}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPayload(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        {button !== undefined && (
          <Button onClick={() => deleteButton(button!.id)} color="error">
            Delete
          </Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          disabled={name === "" || topic === "" || payload === ""}
          variant="contained"
        >
          {button === undefined ? "Create" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditButtonDialog;
