import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useButtonGroupStore } from "../buttonGroupStore";
import { AdminButtonGroup } from "../AdminButtonGroup";

interface DeleteButtonGroupDialogProps {
  group: AdminButtonGroup | undefined;
  handleClose: () => void;
  handleSuccess: () => void;
}

const DeleteButtonGroupDialog = ({
  group,
  handleClose,
  handleSuccess,
}: DeleteButtonGroupDialogProps) => {
  const deleteGroup = useButtonGroupStore((state) => state.deleteGroup);

  const handleDelete = () => {
    if (group) {
      deleteGroup(group?.id, handleSuccess);
    }
    handleClose();
  };

  return (
    <Dialog
      open={group !== undefined}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm Delete Group"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are about to delete a button group. This will also remove all
          child buttons.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="error"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteButtonGroupDialog;
